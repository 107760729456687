@media only screen and (min-width: 1024px) {
  .symptoms_icons {
    display: grid;
    grid-template-columns: 6vw 6vw 6vw;
    grid-column-gap: 2vw;
    margin-top: 4.5vh;
  }

  .symptoms {
    height: 12vh;
    width: 15vw;
  }
  .card_img {
    height: 21vh;
    width: 8vw;
  }
  .card_div {
    display: flex;
    width: 30vw;
  }
  .symptom_second_main_div {
    margin-left: 30vw;
    display: flex;
    align-items: center;
    display: flex;
    gap: 9vw;
    margin-top: 3vh;
  }
  .main_div {
    height: 100vh;
  }
  .search_by_symptoms {
    overflow-y: scroll;
    height: 55vh;
    z-index: 1;
  }
  .category {
    display: flex;
    align-items: center;
    gap: 9.5vw;
  }
  .serach_speciality {
    width: 25vw;
  }
  .search_by_speciality {
    height: 60vh;
    overflow-y: scroll;
  }
  .under_heder {
    height: fit-content;
    margin-top: 5vh;
    margin-left: 30vw;
  }
  .box_div {
    margin-top: 2vh;
  }
  .search_div {
    display: flex;
    align-items: center;
    width: 35vw;
    margin-top: 5vh;
    padding: 0.5em 0.5em 0.5em 1.5em;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.03);
    margin-left: 7vw;
    border-radius: 12px;
  }
  .search_icon {
    width: 20px;
    height: 20px;
    margin-left: 18vw;
  }
  .keywords {
    display: flex;
    align-items: center;
    margin-left: 6vw;
  }
  .plus {
    background: #717ef0;
    border-radius: 5px;
    padding: 0.2em 0.5em;
  }
  .key {
    background: #ffffff;
    box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.04);
    padding: 0.3em 0.5em;
    border-radius: 5px;
  }
  .content_div {
    margin-top: 13vh;
  }
  .hading1 {
    font-weight: 500;
  }
  .hading2 {
    font-weight: 500;
  }
}

@media only screen and (max-width: 1023px) {
  .main_div {
    width: fit-content;
  }
  .symptom_second_main_div {
    display: flex;
    flex-direction: column;
    margin-left: 18vw;
    margin-top: 1vh;
    width: fit-content;
  }
  .search_by_symptoms {
    margin-top: 2vh;
    height: fit-content;
    z-index: 1;
    margin-left: -12vw;
  }
  .keywords {
    display: none;
  }
  .search_div {
    display: flex;
    align-items: center;
    width: 85vw;
    margin-left: -14vw;
    padding: 0.8em 0.8em 0.5em 1.5em;
    margin-top: -1vh;
    /* background: red; */
  }
  .symptoms_icons {
    display: flex;
    overflow-x: scroll;
    margin-top: 4.5vh;
  }
  .symptoms {
    height: 10vh;
    width: 22vw;
  }
  .hading1 {
    position: absolute;
    font-size: 1.1rem;
    font-weight: 500;
  }
  .hading2 {
    position: absolute;
    margin-top: -7vh;
    margin-left: -1vw;
    font-size: 1.1rem;
    font-weight: 500;
  }
  .search_by_speciality {
    width: 90vw;
    margin-left: -11vw;
    margin-top: 9vh;
    overflow-y: scroll;
  }
  .card_img {
    height: 20vh;
    width: 35vw;
  }
  .card_div {
    display: grid;
    grid-template-columns: 36vw 36vw 36vw;
    overflow-x: scroll;
    width: 100vw;
  }
  #view {
    display: none;
  }
  .category {
    display: flex;
    align-items: center;
    grid-gap: 9.5vw;
    gap: 9.5vw;
    margin-top: -1vh;
  }
  .related {
    font-weight: 500;
    color: #000000;
  }
  .main_div {
    height: 100vh;
    width: 100%;
  }
  .serach_speciality {
    width: 82vw;
    overflow-x: scroll;
    margin-left: 1vw;
    margin-top: 1vh;
  }
  .under_heder {
    height: fit-content;
    margin-top: 5vh;
    margin-left: 21vw;
  }
  .related {
    font-weight: 500;
    color: #000000;
    margin-top: 1vh;
  }
}
