@media only screen and (min-width: 1024px) {
  #doctor_available_div {
    background: red;
    width: 30vw;
    margin-top: -5vh;
    position: absolute;
  }
  #found_doctor_main_div {
    margin-top: -5vh;
    position: absolute;
  }
  #found_doctor_back {
    margin-top: 3vh;
    position: absolute;
  }
  /* medicine type css starts here  */
  .allopathy_image {
    height: 111px;
    width: 150px;
    margin-top: -12%;
    margin-left: -10%;
  }
  #homeopathy_text {
    padding-bottom: 4vh;
    margin-left: 1vw;
    font-size: 1.3rem;
  }
  #allopathy_text {
    padding-bottom: 4vh;
    font-size: 1.3rem;
  }
  #ayurvedic_text {
    /* padding-bottom: 3vh; */
    font-size: 1.3rem;
  }
  #main_medicinetype_div {
    display: flex;
    align-items: center;
    gap: 5vw;
    margin-top: 13vh;
    margin-left: -22vw;
  }
  #medicine_div {
    display: flex;
    flex-direction: column;
    height: 45vh;
    width: 40vw;
    padding: 2em 6em;
  }
  #medicine_div_1 {
    display: flex;
    flex-direction: column;
    height: 45vh;
    padding: 2em 6.5em;
    margin-top: 2.5vh;
  }
  #medicine_div_2 {
    display: flex;
    flex-direction: column-reverse;
    height: 45vh;
    width: 40vw !important;
    padding: 2em 6em;
  }
  #medicine_image {
    height: 111px;
    width: 30vw;
  }
  #web_typeofmedicine {
    margin-top: 4vh;
    margin-left: 7vw;
    position: absolute;
  }
  #web_go_back {
    position: absolute;
    margin-left: -30vw;
  }
  /* medicine type css starts here  */
}

@media only screen and (max-width: 1024px) {
  .homeopathy_image {
    margin-left: -13vw;
  }
  .choose-head {
    margin-left: 5vw;
  }
  .selection-div {
    width: 80vw;
    height: 17vh;
    padding: 0;
  }
  .selection-images {
    height: 115px;
    width: 25vw;
    margin-top: 2.9vh;
    margin-left: 5vw;
  }
  #homeopathy_text {
    margin-left: 5vw;
  }
  /* medicine type css starts here  */
  #medicine_div_1 {
    margin-left: 2vw;
    margin-right: 2vw;
    display: flex;
    align-items: center;
    height: 20vh;
    padding: 3em 6.5em;
    margin-top: 2.5vh;
  }
  #medicine_div_2 {
    margin-left: 2vw;
    margin-right: 2vw;
    display: flex;
    align-items: center;
    gap: 5vw;
    height: 20vh;
    padding: 3em 6em;
  }
  #medicine_div {
    margin-left: 2vw;
    margin-right: 2vw;
    display: flex;
    height: 20vh;
    gap: 12vw;
    padding: 2em 6em;
  }
  #main_medicinetype_div {
    margin-top: -2vh;
  }
  #homeopathy_text {
    margin-left: 1vw;
    font-size: 1.3rem;
  }
  #allopathy_text {
    margin-left: -4vh;
    padding-right: 3vh;
    font-size: 1.3rem;
  }
  #ayurvedic_text {
    margin-left: -7vw;
    font-size: 1.3rem;
  }
  /* medicine type css starts here  */
}