.search::placeholder {
  color: black;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border:none;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 143px;
  color: #A1A1A1;
}

.title::placeholder{
  color: black;
}

.search::placeholder{
  color: black;
}

.ant-popover-placement-top{
  left:58px;
}

.ant-popover-placement-top, .ant-popover-placement-topLeft, .ant-popover-placement-topRight{
  margin-left: 58px;
}

.ant-select-arrow{
  color:black;
  right:15px;
}