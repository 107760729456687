@media only screen and (min-width: 1024px) {
  .doctor_profile_pic {
    width: 11vw;
    height: 11vw;
    border-radius: 50%;
    margin-top: -6vh;
    position: absolute;
  }
  .modal_img {
    height: 60vh;
    width: 25vw;
    max-height: 60vh;
    max-width: 25vw;
  }
  #edit_doctor_profile {
    margin-top: -8vh;
    margin-left: 6vw;
  }
  .line_breaker {
    width: 100%;
  }
  .main_div {
    display: flex;
    height: 100vh;
  }
  .details_div {
    display: flex;
    margin-left: 2vw;
    margin-top: -28vh;
  }
  .publication_patient_care {
    display: flex;
    gap: 4vw;
    margin-left: 2vw;
  }
  .second_main_div {
    margin-top: 32vh;
    margin-left: 25vw;
    display: flex;
    flex-direction: column;
  }
  .doc_patient_publications {
    margin-left: 8vw;
    margin-top: -2vh;
  }
  #profile_contents {
    padding: 0.1em 0.5em;
    width: 30vw;
    margin-left: 6vw;
  }
  .patient_under_care {
    width: 30vw;
    background: #f5f5ff;
    padding: 0.1em 1em;
    margin-top: 9vh;
    border-radius: 16.3014px;
  }
  .publications {
    padding: -1em -1em;
    height: fit-content;
    margin-top: 3vh;
  }
  #horizon {
    display: none;
  }
  .modal_image {
    height: 60vh;
    max-height: 70vh;
    width: 30vw;
    max-width: 30vw;
  }
  #mobile_edit {
    display: none;
  }
  .doctor_display_name {
    margin-top: 9vh;
    margin-left: 12vw;
  }
  .edit_doctor {
    margin-top: -9vh;
    position: absolute;
    margin-left: 6vw;
    z-index: 89;
  }
  #goback_doctor{
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .doctor_profile_pic {
    width: 44vw;
    height: 43vw;
    border-radius: 50%;
    margin-top: 7vh;
  }
  #edit_doctor_profile {
    margin-top: -6vh;
    margin-left: 18.5vw;
  }
  .doctor_display_name {
    margin-top: -6vh;
  }
  .modal_img {
    height: 60vh;
    width: 83vw;
    max-height: 60vh;
    max-width: 80vw;
    position: fixed;
    margin-left: 6vw;
    padding: 1em 0.5em;
    /* margin-top: 2vh; */
  }
  .line_breaker {
    width: 88%;
  }
  #profile_contents {
    padding: 0.1em 0.5em;
    width: 85vw;
    border-color: red;
  }
  #title_content {
    padding: 1.5em 1em;
  }
  .doc_patient_publications {
    margin-top: -1vh;
  }
  .publications {
    margin-top: 1.5vh;
    margin-bottom: 2.5vh;
    padding: 0.7em;
  }
  .edit_doctor {
    display: none;
  }
  .doctor_display_name {
    margin-top: 8vh;
    margin-left: 5vw;
  }
}
