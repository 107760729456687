.ant-model{
    max-width:calc(100vw);
    position: none;
    margin:0px auto !important;
}
.ant-modal-footer{
    display: none;
}
.ant-modal-header{
    display: none;
}
.ant-modal-close{
    left:0;
}