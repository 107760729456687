.navbar{
   z-index: 1;
   color:white;
}
.nav_links{
    display: none;
}
.nav_links_mobile{
    margin-top: 20px;
    display: block;
    position: absolute;
    list-style: none;
    background-color:black;
    transition: all 0.5s ease-out;
    width: 90%;    
    z-index: 999;
}
.nav_item{
    text-align: center;
    padding: 20px;
    width: 100%;
    transition: all 0.02s ease;
}
.mobile-menu-icon{
    display: block;
    /* position: absolute; */
    font-size: 20px;
    z-index: 999;
    color: black;
    /* background-color:purple; */
    border: none;
    outline: none;
}