@media only screen and (min-width: 1024px) {
  .patient_details {
    display: flex;
    margin-left: 28vw;
  }
  .patient_info {
    width: 34vw;
    margin-left: 4vw;
    margin-top: 18vh;
  }
  .sidebar {
    width: 23vw;
    height: 100vh;
  }
  .patient_profile_pic {
    vertical-align: middle;
    width: 13vw;
    height: 13vw;
    border-radius: 50%;
    margin-top: 5vh;
    /* z-index: -1; */
    position: absolute;
  }
  #profile_pic_upload {
    margin-top: 18vh;
    margin-left: -3vw;
  }
  /* .text-black {
    display: none;
  } */
  .patient_display_name {
    margin-left: 13vw;
    margin-top: 28vh;
  }
  #content_context {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    padding-left: 1.5em;
  }
  #edit_profile {
    display: none;
  }
  .go_edit {
    margin-top: 3vh;
    position: absolute;
    margin-left: 7vw;
    z-index: 91;
  }
  .profile_heading {
    height: fit-content;
    width: 19vw;
    position: absolute;
    margin-left: 22vw;
    font-weight: 500;
    font-size: 40px;
    line-height: 75px;
  }
  .modal_img {
    height: 60vh;
    max-height: 70vh;
    width: 30vw;
    max-width: 30vw;
  }
}

@media only screen and (max-width: 768px) {
  .patient_profile_pic {
    width: 43vw;
    height: 41vw;
    border-radius: 50%;
    margin-top: 7vh;
    margin-left: 8vw;
  }
  .patient_display_name {
    margin-left: 1vw;
  }
  .truncate {
    max-width: 50vw;
  }
  .edit_patient_profile_pic {
    width: 43vw;
    height: 41vw;
    border-radius: 50%;
    margin-top: 3vh;
  }
  #contents {
    width: 80vw;
  }
  #content_context {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1.5em;
  }
  .go_edit {
    display: none;
  }
  .profile_heading {
    display: none;
  }
  .patient_info {
    margin-left: 9vw;
  }
}