#divInner {
  left: 0;
  position: sticky;
}

.styles_react-code-input-container__tpiKG {
  width: 100% !important;
}

.styles_react-code-input__CRulA > input {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  margin-right: 5px;
}

.styles_react-code-input__CRulA > input:first-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.styles_react-code-input__CRulA > input:last-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
#divOuter {
  overflow: hidden;
}
.container {
  max-height: 100vh;
  overflow-y: hidden;
}

.resend {
  text-decoration-color: black;
}


@media only screen and (min-width: 1023px) {
  .verification_box {
    margin-left: 45vw;
  }
  
}

@media (min-width: 600px) {
  .resend {
    text-decoration-color: #929bff;
  }
}
