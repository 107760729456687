@media only screen and (min-width: 1024px) {
    .sidebar {
        height: 100vh;
        position: fixed;
        overflow: scroll;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
    .logo {
        margin: auto;
        width: 199px !important;
        justify-content: center;
        align-items: center;
        margin-top: 12%;
        margin-bottom: 12% !important;
    }
    .svg {
        margin-left: 16%;
        margin-top: 4.8%;
    }
    .box {
        height: 80vh;
        background-color: #F5F5FF;
        position: fixed;
        width: 23vw;
    }
    p {
        margin-top: 4.8%;
        margin-left: 20px;
    }
    .content {
        margin-bottom: 6%;
    }
}