@media only screen and (max-width: 1023px) {
    .search-appoint{
        width: 90%;
    }
    .header-appointment{
        display: none;
    }
}

@media only screen and (min-width: 1024px) {
    .search-appoint{
        width: 72%;
        margin-bottom: 3%;
    }
    .webView{
        display:flex
    }
    .header-appointment{
        display:flex;
        justify-content: space-between;
    }
    .webView2{
        margin-left: 2.5rem;
        margin-right: 2.5rem;
    }
}
