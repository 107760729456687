@media only screen and (max-width: 1023px) {
    .prescription-grid {
        width: 92vw;
        margin-left: 1.3vw;
    }
    .heading-presc {
        display: none;
    }
    .search-presc {
        width: 95%;
    }
    .modal-uploads{
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(1px)
    }
}

@media only screen and (min-width: 1024px) {
    .prescription-grid {
        width: 72vw;
        margin-left: 2.3vw;
    }
    .heading-presc {
        margin-top: 7vh;
    }
    .search-presc {
        width: 72%;
    }
    .modal-uploads {
        margin-left: -0.5%;
    }
}