@media only screen and (min-width: 1024px) {
  .sidebar {
    /* width: 23%; */
    height: 100vh;
    overflow: scroll;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  .logo {
      margin-top: 10vh;
      z-index: 99
  }
  .svg {
    margin-left: 16%;
    margin-top: 4.8%;
  }
  .patient_box {
    height: 100vh;
    margin-top: 20vh;
    overflow-x: scroll;
    z-index: -1;
    
    scroll-behavior: smooth;
    background-color: #f5f5ff;
  }
  p {
    margin-top: 4.8%;
    margin-left: 20px;
  }
  .content {
    margin-bottom: 6%;
  }
  .type {
    margin-left: 12%;
    margin-right: 10%;
    margin-top: 1.2rem;
    border-radius: 10px;
  }
}
