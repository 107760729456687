@media only screen and (min-width: 1024px) {
  #patient_edit_from {
    display: flex;
    flex-direction: column;
    margin-left: 15vw;
    margin-top: 15vh;
  }
  .profile_edit {
    display: flex;
  }
  .edit_heading {
    display: none;
  }
  .go_back {
    display: none;
  }
  #patient_edit_profile {
    margin-left: 33vw;
    height: fit-content;
    margin-top: 30vh;
  }
  .edit_patient_profile_pic {
    vertical-align: middle;
    width: 13vw;
    height: 13vw;
    border-radius: 50%;
    margin-top: 5vh;
  }
  .upload_image {
    width: 32vw;
  }

  #submit_btn {
    padding: 1em 0.5em;
  }
  .btn_text {
    font-size: 1.2rem;
    margin-top: -2vh;
    margin-left: -1vw;
  }
  #profile_pic_upload {
    margin-top: -17vh;
    margin-left: -5vw;
  }
}
@media only screen and (max-width: 768px) {
  .upload_image {
    width: 96vw;
    margin-left: 1vw;
  }
  #profile_pic_upload {
    margin-top: 14vh;
    margin-left: -13vw;
  }
}
