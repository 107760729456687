@media only screen and (max-width: 1023px) {
    .websites {
        display: none;
    }
    .profile{
        display: none;
    }
    .patient-card {
        width: 92%;
    }
    .sub-heading{
        font-size: 16px;
        margin-left: -49px
    }
    .icon{
        display: none;
    }
    .searchbar{
        width: 95%;
    }
}

@media only screen and (min-width: 1024px) {
    .mobile {
        display: none;
    }
    .websites {
        width: 23%;
    }
    .web {
        width: 77%;
        height: 100vh;
        overflow:scroll;
    }
    .rest {
        display: flex;
        margin-top: 3vh
    }
    .first {
        width: 39%;
        margin-left: -15%;
    }
    .buttons-doctor {
        display: grid;
        grid-template-columns: repeat(3, 20%);
        column-gap: 3vw;
        justify-content: center;
        margin-top: 1vh;
        margin-left: -3vw;
    }
    .btn-text {
        margin-left: auto;
        margin-right: auto;
    }
    .card {
        margin-left: 9vw;
        margin-top: -20%;
    }
    .space2 {
        width: 13rem;
        /* margin-left: 10px; */
    }
    .girl {
        margin-top: -70px;
    }
    .text {
        margin-left: 4vw;
        margin-bottom: 1vh;
    }
    .width {
        width: 2rem;
        height: 2rem
    }
    .second {
        margin-left: 30%;
        width: 60vw;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
        border-radius: 16.3014px;
    }
    .patient-card {
        width: 27vw;
        margin-left: 2vw;
        margin-right: 2vw;
    }
    .sub-heading{
        font-size: 16px;
        margin-left: -49px
    }
    .searchbar{
        width: 70%;
        margin-left: -20%;
    }
    .namaste{
        margin-top: 4vh
    }
    .header-doctor{
        justify-content:space-between;
        display: flex;
    }
    .icon{
        width: 20%;
        height: 20%;
        margin-right: -7vw;
    }
}