.ant-picker-input>input {
    font-size: 12px !important;
    margin-left: 2px;
}

.ant-input {
    font-size: 12px;
}

.anticon-calender {
    color: black;
}

.ant-select-single {
    font-size: 12px !important;
}

input[type='radio']:after {
    /* width: 15px;
    height: 15px; */
    border-radius: 50%;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #C1C1C1;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: #C4C4C4;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #C1C1C1;
}