@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

@import '~antd/dist/antd.css';


body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color:transparent;
  user-select: none;
  -webkit-user-select: none;
  outline: none;
}

input{
  outline: none;
}

button:active, button {
  outline: none;
}

body::selection {
  background: #000; /* WebKit/Blink Browsers */
}
body::-moz-selection {
  background: #000; /* Gecko Browsers */
}

code {
  font-family: 'Poppins', sans-serif;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black ;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black ;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: black ;
}

.ant-picker-input > input{
  font-size: 12px !important;
}

.ant-input[disabled]{
  background: #ffffff !important;
}

.ant-drawer-body{
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.ant-modal-content{
  width: 100vw
}