@media only screen and (min-width: 1024px) {
  .main_appoinment_from {
    margin-left: 23vw;
  }
  .appoinment_from_heading {
    font-family: "Poppins", sans-serif;
    width: fit-content;
    margin-left: 18vw;
    margin-top: 5vh;
    font-size: 2rem;
  }
  .radio_btn {
    display: flex;
    gap: 5px;
    padding-left: 1em;
  }
  .appoinment_form {
    display: flex;
    margin-left: 8vw;
    margin-top: 10vh;
  }
  .radio_btn {
    display: flex;
    padding-right: 1em;
    /* padding-left: 1em; */
    gap: 3.5vw;
  }
  .second_form_section {
    margin-left: 2vw;
  }
  .appoinment_next_btn {
    width: 53.5vw;
    margin-left: 8.8vw;
    background-color: #c4c4c4;
    padding: 0.6em 0.5em;
    margin-top: 6vh;
    border-radius: 2px;
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
  }
  .left_arrow_back {
    display: none;
  }
  #name_input {
    width: 24.25rem;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 11px;
    margin-top: 12px;
  }
  #appoinment_pin {
    width: 11.25rem;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 11px;
    margin-top: 12px;
  }
  #appoinment_state {
    color: black;
    width: 18.25vw;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px !important;
  }
  #appoinment_date {
    color: black;
    width: 24.25rem;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px !important;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  #appoinmet_state {
    width: 12.25rem;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 11px;
    margin-top: 12px;
  }
  #radio_para{
   margin-top: 5px;
  }
}
@media only screen and (max-width: 1024px) {
  .appoinment_form {
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
  }
  .radio_btn {
    display: flex;
    gap: 6vw;
    padding-left: 2.5em;
  }
  #radio_para{
    padding-top: 5px;
    margin-left: 3vw;
  }
  #state_wp {
    display: flex;
    flex-direction: column;
  }
  #name_input {
    width: 82vw;
  }
  #appoinment_pin {
    width: 82vw;
  }
  #appoinmet_state {
    width: 82vw;
  }
  #appoinment_date {
    width: 82vw;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .appoinment_next_btn {
    width: 83.2vw;
    margin-left: 8.2vw;
    background-color: #c4c4c4;
    padding: 0.6em 0.5em;
    margin-top: 18vh;
    border-radius: 2px;
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
  }
  .appoinment_from_heading {
    margin-left: 10vw;
    margin-top: 6vh;
    font-size: 1.3rem;
    font-weight: 600;
  }
  .left_arrow_back {
    margin-top: 3vh;
    margin-left: 6vw;
  }
}
