input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.container {
  overflow-y: hidden;
}

@media only screen and (max-width: 1023px) {
  .selection-div {
    padding: 10px;
    margin: 1vh 0;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  }
  .selection-images {
    height: 20vh;
    width: 30vw;
  }
}
@media only screen and (min-width: 1024px) {
  .selection-div {
    cursor: pointer;
    height: 45vh;
    width: 40vw;
    /* padding: 2vw; */
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
    flex-direction: column-reverse;
    margin: 0 3vw 0 0;
  }
  .selection-images {
    margin-left: 2vw;
    height: 20vh;
    width: 30vw;
  }
  .otp_box{
    margin-left: 45vw;
  }
  #get_otp_btn{
    margin-top: 25vh;
  }
}
