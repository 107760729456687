/*  */
@media only screen and (min-width: 1024px) {
  .main_book_doctor {
    display: flex;
  }
  .book_doctor_div {
    height: 100vh;
    margin-left: 23vw;
    width: 77vw;
  }
  .bookdoctor_under_heder {
    margin-left: 10vw;
  }
  .appoinment {
    width: 35vw;
    height: 25vh;
    margin-top: 1vh;
    margin-left: 3vw;
  }
  .doctor_booking_details {
    display: flex;
    margin-top: 8vh;
  }
  .calender {
    width: 25vw;
    height: 50vh;
    margin-left: 4vw;
    padding: 1.5em 1.5em 1.5em 1.5em;
    border-radius: 0.8em;
  }
  #goback_btn {
    display: none;
  }
  .next_btn {
    width: 25vw;
    background-color: #929bff;
    padding: 0.6em 0.6em;
    border-radius: 9px;
    color: white;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-top: 2vh;
    margin-left: 5vw;
  }
}
@media only screen and (max-width: 1023px) {
  #goback_btn {
    margin-top: 3vh;
    margin-left: 5vw;
  }
  .booking_div {
    margin-top: 5vh;
    margin-left: 20vw;
  }
  .appoinment {
    margin-top: 7vh;
  }
  .calender {
    width: 90vw;
    margin-top: 3vh;
    margin-left: 5vw;
  }
}
