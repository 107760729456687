@media only screen and (min-width: 1024px) {
  /* ==================patients chat page styles======================= */
  #chat_header {
    display: flex;
    width: 77vw;
    margin-left: 23vw;
  }
  #chats {
    width: 65vw;
    margin-left: 29vw;
    margin-top: 1vh;
    margin-bottom: 8vh;
  }
  #chats_inputs {
    width: 73vw;
    margin-left: 23vw;
    margin-top: -5vh;
  }
  #sender_message_inputs {
    width: 55vw;
    margin-top: 3vh;
  }
  #request_for_a_call {
    display: flex;
    align-items: center;
    margin-top: -20vh;
    padding: 1.5em 1em;
    width: 30vw;
    margin-left: 24vw;
  }
  #message_box_width {
   width: fit-content;
   max-width: 25vw;
  }
  #call_request {
    margin-top: -2vh;
  }
  #call_text {
    margin-top: -0.5vh;
  }
  #file_uploads_btn {
    margin-top: 3.5vh;
  }
  #patient_upload_image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50vh;
    margin-left: 21vw;
    height: fit-content;
    width: 60vw;
  }
  /* ==================patients chat page styles======================= */

  /* ===================doctor chat page style=========================  */
  #doctor_chat_header {
    width: 77vw;
    margin-left: 23vw;
  }
  #doctor_chat_inputs {
    width: 65vw;
    margin-left: 6vw;
  }
  #doctor_input_sender {
    width: 65vw;
  }
  #doctor_chats {
    margin-top: 1vh;
    width: 65vw;
    margin-left: 6vw;
  }
  #doctor_upload_image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -15vh;
    margin-left: 2vw;
    height: fit-content;
    width: 60vw;
  }
  .prescription_modal {
    width: 60vw;
    position: absolute;
    background: white;
    height: 160vh;
  }
  .prescription_header {
    font-size: 1.3rem;
    font-weight: 900;
    margin-top: 3vh;
  }
  .save_a_copy_btn {
    width: 20vw;
    background: #929bff;
    padding: 1em 0.5em;
    text-align: center;
    color: white;
    margin-top: -0.5vh;
    border-radius: 3px;
  }
  #prescription_next_btn {
    margin-left: -2vw;
  }

  /* ===================doctor chat page style=========================  */

  /* =====================doctor prescription css starts here=========================  */
  #evening_checkbox{
    margin-top: -0.2vh;
  }
  /* =====================doctor prescription css starts here=========================  */
}
@media only screen and (max-width: 1023px) {
  #sender_message_inputs {
    /* background: red; */
    width: 16.813rem;
  }
  #patient_upload_image {
    margin-top: -47vh;
    width: 90vw;
    position: absolute;
    height: fit-content;
  }
  .save_a_copy_btn {
    background: #929bff;
    margin-left: 15vw;
    padding-left: 2em;
    padding-top: 0.5em;
    width: 40vw;
    color: white;
  }

  .prescription_modal {
    width: 100vw;
    margin-left: 12vw;
    height: 160vh;
  }
  .prescription_header {
    font-size: 1.3rem;
    font-weight: 900;
    margin-top: 3vh;
  }
}
