@media only screen and (max-width: 1023px) {
    .call {
        padding-left: 5px;
    }
    .ambulance-text {
        font-weight: 500;
        font-size: 24px;
        margin-left: 8%;
        margin-top: 10%;
        margin-bottom: -7%;
    }
    .search-mail {
        width: 85%;
        margin-top: 4rem;
        margin-left: 7vw;
    }
    .textarea-email {
        width: 100%;
        margin-left: 8vw;
    }
    .send-email{
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 10%;
    }
    .attach-email{
        width: 85%;
        padding-top: 4%;
        padding-bottom: 4%;
        margin-left: 8vw;
    }
    .attachment-email{
        margin-top: 15px; 
        margin-left: 30px;  
        width: 85%;
        padding-bottom:1.2rem
    }
}
@media only screen and (min-width: 1024px) {
    .info-cards {
        width: 45%;
    }
    .call {
        padding-left: 15%
    }
    .ambulance-text {
        font-weight: 500;
        font-size: 40px;
        text-align: center;
        margin-top: -4%;
    }
    .search-mail {
        width: 55%;
        margin-left: 23%;
        margin-top: 2rem;
    }
    .textarea-email {
        width: 100%;
        margin-left: 8%;
        margin-right: 8%;
    }
    .send-email{
        width: 28%;
        margin-top: 2vh;
        padding: 0.5em 0.5em;
        height:8%
    }
    .web-email{
        display:flex;
        width:56%;
        justify-content:space-between;
    }
    .attach-email{
        width:60%;
        margin-left:6vw;
        padding-top: 2%;
        padding-bottom: 2%;
    }
    .attachment-email{
        margin-top:-5%; 
        margin-left: 7%;  
        width: 25%;
        padding-bottom:1%;
        position: absolute;
        z-index: 10;
    }
}