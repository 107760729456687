@media only screen and (max-width: 1023px) {
    .observation {
        width: 80vw
    }
    .medicine {
        width: 80vw
    }
    .second-block1 {
        display: flex
    }
    .line {
        display: none
    }
    .medicine-head {
        margin-top: 4%;
    }
    .morning {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
    .note-desc {
        padding-bottom: 4%
    }
    .note-main {
        padding-left: 0.9rem;
    }
    .options {
        font-size: 12px;
        padding: 1% 5%;
    }
    .option {
        font-size: 12px;
        padding: 1% 2%;
    }
    .options-follow {
        font-size: 12px;
        padding: 1% 7.2%;
    }
    .second-block3 {
        margin-top: 4%;
    }
    .sub-block {
        width: 210%;
    }
    .save-block {
        width: 85%;
        /* margin-left: -4%; */
    }
    .save-text-block {
        margin-top: 2%;
        width: 8rem;
        height: 2rem
    }
    .save-text {
        padding-top: 5%;
        font-size: 12px;
    }
}

@media only screen and (min-width: 1024px) {
    .prescription-head {
        text-align: center;
        margin-top: 2vh;
        font-weight: 500;
        font-size: 30px;
    }
    .observation {
        width: 92%
    }
    .observation-head {
        padding-left: 2%;
        font-size: 16px;
    }
    .object-contain {
        margin-left: 3%;
    }
    .medicine {
        width: 92%
    }
    .diagnostic {
        width: 92%;
        margin-left: 4%;
        margin-bottom: 2%;
    }
    .second-block {
        display: flex
    }
    .second-block1 {
        font-size: 16px;
        padding-left: 2%;
    }
    .days-of-medicine {
        margin-top: 5%;
        font-size: 16px;
    }
    .time-block {
        margin-top: 9%;
        font-size: 16px;
    }
    .medicine-name {
        margin-top: 6%
    }
    .note {
        font-size: 16px;
        margin-top: 20%
    }
    .medicine-head {
        font-size: 16px;
        margin-left: 4%;
        margin-top: 2%;
        margin-bottom: 1%;
    }
    .note-desc {
        font-size: 16px;
        margin-top: 9%
    }
    .food {
        margin-top: 2%;
        font-size: 16px;
    }
    .morning {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
    .second-block3 {
        display: flex;
        margin-left: 4%;
    }
    .options {
        font-size: 16px;
        padding: 1% 10%;
    }
    .options-follow {
        font-size: 16px;
        padding: 1% 10%;
    }
    .option {
        font-size: 16px;
        padding: 1% 5%;
    }
    .sub-block {
        width: 100%;
    }
    .save-block {
        width: 72%;
    }
    .save-text-block {
        padding: 1% 2%;
        width: 11rem;
        margin-left: 45%;
    }
    .save-text {
        font-size: 16px;
    }
}