@media only screen and (max-width: 1023px) {
    .card {
        margin-top: 23px;
        margin-left: 3vw;
        margin-right: 3vw;
        background: #FCFCFC;
        box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
        border-radius: 18px 18px 0px 0px
    }
    .profile_pic {
        height: 17vh;
        width: 34vw;
        border-radius: 50%;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
    .display_name {
        margin-top: -3vh;
        margin-left: 7vw;
    }
    .spec {
        margin-top: 0.5vh;
    }
    .content-appoint {
        margin-left: 5vw;
        margin-right: 5vw;
        padding-top: 1.5vh;
        padding-left: 5vw;
        padding-right: 5vw;
        padding-bottom: 1.5vh;
    }
    .time {
        padding: 1vh 2vh;
        color: rgba(166, 174, 255, 0.8);
        font-size: 15px;
    }
    .booked {
        padding: 1vh 5.7vh
    }
    .next {
        width: 80%;
        margin-left: 9vw;
        margin-right: 7vw;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    .appointment-time-heading {
        display: none;
    }
    .appointment-time {
        padding-left: 5vw;
        padding-right: 5vw;
        padding-top: 2vh;
        padding-bottom: 4vh;
        margin-top: 3vh;
    }
    .successful-text {
        font-size: 35px;
        line-height: 67px;
        color: #435561;
        text-align: center;
    }
    .successful-image {
        height: 96.67px
    }
    .successfull-phone {
        display: flex;
        flex-direction: column-reverse;
    }
    .successful {
        padding-top: 2vh;
        padding-bottom: 2vh;
    }
    .doctor-card-successful {
        border: 1px solid #929BFF;
        box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        padding-bottom: 2vh;
    }
    .appointment-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #929BFF;
        text-align: center;
        margin-top: 2vh;
    }
}

@media only screen and (min-width: 1024px) {
    .web-appoint {
        display: flex;
        margin-top: 40vh;
    }
    .profile-doc-appoint {
        margin-bottom: 5vh;
    }
    .doctor-card {
        margin-left: -4vw;
        margin-right: -2vw;
    }
    .profile_pic {
        height: 20vh;
        width: 20vh;
        margin-right: 2vw;
    }
    .content-appoint {
        padding-top: 1.5vh;
        padding-left: 2vw;
        padding-right: 2vw;
        padding-bottom: 1.5vh;
    }
    .content-appoint-main {
        width: 125%;
        margin-bottom: 2vh;
    }
    .next-web {
        width: 125%;
        /* margin-top: 2vh; */
    }
    .time {
        padding: 1vh 2vh;
        font-size: 18px;
    }
    .booked {
        padding: 1vh 7vh
    }
    .next {
        width: 25vw;
        /* margin-top: 1vh; */
    }
    .appointment-time {
        margin-left: 15vw;
        padding-left: 2vw;
        padding-right: 2vw;
        padding-top: 4vh;
        padding-bottom: 4vh;
    }
    .appointment-time-heading {
        font-size: 20px;
        line-height: 23px;
        color: #797979;
    }
    .successful-text {
        font-size: 35px;
        line-height: 67px;
        color: #435561;
        text-align: center;
    }
    /* .successful {
        margin-left: 5vw;
    } */
    .appointment-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #929BFF;
        padding-top:1vh;
        padding-bottom: 1vh;
        width:125%;
        text-align: center;
        margin-top: 2vh;
        border: 1px solid #929BFF;
        border-radius: 9px;
    }
    .success-main{
        margin-left: 8vw;
    }
    .successful-main-div{
        margin-left:4rem;
    }

}