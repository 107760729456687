@media only screen and (max-width: 1023px) {
  .web {
    width: 100%;
  }
  .sidebar {
    display: none;
  }
  .quiz {
    display: none;
  }
  .buttons {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    column-gap: 3vw;
    justify-content: center;
    margin-top: 1vh;
  }
  .buttons > button {
    margin-top: 1vh;
  }
  .Book {
    width: 100%;
    margin-top: 15px;
  }
  .icon-patient {
    display: none;
  }
  .searchb {
    width: 95%;
  }
}

@media only screen and (min-width: 1024px) {
  .mobile {
    display: none;
  }
  .web {
    width: 77%;
    height: 100vh;
    overflow: scroll;
  }
  .websites {
    width: 23%;
  }
  .searchb {
    width: 48vw;
    margin-left: 5%;
  }
  .buttons {
    text-align: center;
    margin-top: 2vh;
  }
  .buttons > button {
    margin-left: 1vw;
  }
  .btn-text {
    margin-left: auto;
    margin-right: auto;
  }
  .Book {
    margin-left: 10%;
    /* height:132px; */
  }
  .arrow {
    margin-right: 20px;
  }
  .text-patient {
    margin-left: 10px;
    margin-right: 10px;
  }
  .quiz {
    margin-left: 2%;
    width: 40%;
  }
  .quiz-content {
    height: 86%;
    margin-top: 6%;
  }
  .header-patient {
    justify-content: space-between;
    display: flex;
  }
  .icon-patient {
    width: 15%;
    height: 20%;
    margin-right: 3vw;
  }
  .patient-card-home {
    height: 25vh;
    width: 33vw;
  }
  .image-home {
    height: 20vh;
  }
  .text-home {
    font-size: 14px;
  }
}
