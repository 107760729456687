@media only screen and (min-width: 1024px) {
  .openFile {
    margin-top: 4.5vh;
    margin-left: 5vw;
    height: 4.5rem;
    position: absolute;
  }
  .doctor_editing_profile {
    vertical-align: middle;
    width: 13vw;
    height: 13vw;
    border-radius: 50%;
    margin-top: 7vh;
    margin-left: 3.5vw;
  }
  .second_div {
    display: flex;
    margin-left: 23vw;
  }
  #goback {
    display: none;
  }
  #doctor_from {
    display: flex;
    flex-direction: column;
    margin-left: 15vw;
    margin-top: 5vh;
    /* background-color: red; */
    width: 25vw;
  }
  #profile_div {
    margin-top: 25vh;
    margin-left: 4vw;
  }
  #profile_heading {
    margin-top: -48vh;
    margin-left: 33vw;
    position: absolute;
  }
  .upload_image {
    width: 32vw;
  }
  #form_submit_btn {
    height: 6vh;
    margin-left: 1.4vw;
    width: 21.25rem;
  }
}
@media only screen and (max-width: 1023px) {
  .doctor_editing_profile {
    margin-left: -3vw;
    width: 41vw;
    height: 40vw;
    border-radius: 50%;
    margin-top: 1vh;
  }
  .openFile {
    margin-top: 13vh;
    margin-left: -15vw;
    height: 4.5rem;
  }
  .upload_image {
    width: 96vw;
    margin-left: 1vw;
  }
  #form_submit_btn {
    height: 9vh;
    width: 100vw;
  }
  #profile_heading {
    display: none;
  }
}
