.login-container {
  margin: 0;
  padding: 0;
  background: url("../Assets/backgroundmobile.png");
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.communityform-button {
  @apply fixed md:static bottom-0 left-0 w-full mt-2 text-xl;
}
.community-div {
  flex-direction: column;
}
.blog-img {
  margin: auto;
  height: 30vh;
  width: 48px;
}
.login_box {
  @apply text-center pt-8 text-black;
}
@media only screen and (min-width: 768px) {
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .login-container {
    background-image: url("../Assets/cropDesktop.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
    width: 100vw;
    margin: 0;
  }
  .login_box {
    @apply text-center pt-8 text-black;
    margin-left: 55vw;
  }
  .leftarrow{
    height: 4vh;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 1em;
  }
  #login_box {
    margin-left: 55vw;
  }
  .communityform-button {
    width: 70%;
    border-radius: 10px;
    margin: 3px 15%;
  }
  .community-div {
    flex-direction: row;
  }
  .blog-img {
    margin-top: 3vh;
    width: 30vw;
    height: 40vh;
  }
  .comm-title {
    margin: 5vh auto;
  }
  .blog-title {
    text-align: left;
  }
  .blog-content {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }
  .content-box {
    overflow-y: scroll;
    height: 80vh;
  }
}
