@media only screen and (max-width: 1023px) {
  .comm-div {
    margin-top:15%;
    flex-direction: column;
    align-items: center;
  }
  .comm-image-div {
    padding: 10px;
    margin: 3vh 0;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  }
  .comm-images {
    height: 20vh;
    width: 30vw;
  }
  .comm-text {
    text-align: center;
    height: fit-content;
    width: fit-content;
    margin-top: 7vh;
    margin-left: -10vw;
    font-size:20px;
    font-weight: 400;
  }
  .comm-text-doctor{
    margin-top: 5vh;
  }
}
@media only screen and (min-width: 1024px) {
  .comm-div {
    margin: 15vh 10vw;
  }
  .comm-image-div {
    padding: 2vw;
    margin: 3vh 0;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  }
  .comm-images {
    height: 40vh;
    width: 25vw;
  }

  .comm-text {
    font-size:24px;
    text-align: center;
    margin: 8vh 0;
  }

  .comm-text-doctor{
    margin-top:8vh
  }

  .comm-image-div {
    flex-direction: column-reverse;
    margin: 0 3vw 0 0;
  }
  .selection-div {
    display: flex;
  }
}
