.background 
{
    background-image: url("../Assets/onBack.jpg");
    width: 100vw;
 height: 100vh;
    background-size: cover ;
    background-position: center;
    background-attachment: fixed;
    
}

.container
{
    overflow-y: hidden;
}